import { BookingHistoryPanelComponent } from '@/bookings/components/booking-history-panel/booking-history-panel.component';
import { BookingInfoComponent } from '@/bookings/components/booking-info/booking-info.component';
import {
  BookingReviewPanelComponent,
  ReviewResult,
} from '@/bookings/components/booking-review-panel/booking-review-panel.component';
import { BookingStartPendingPanelComponent } from '@/bookings/components/booking-start-pending-panel/booking-start-pending-panel.component';
import { BookingUpdatePendingPanelComponent } from '@/bookings/components/booking-update-pending-panel/booking-update-pending-panel.component';
import { BookingVerifyPanelComponent } from '@/bookings/components/booking-verify-panel/booking-verify-panel.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { ParticipantsListComponent } from '@/shared/components/participants-list/participants-list.component';
import { RequestPanelComponent } from '@/shared/components/request-panel/request-panel.component';
import { ReviewsComponent } from '@/shared/components/reviews/reviews.component';
import { BookingStatus } from '@/shared/models/BookingStatus';
import { BookingsService, bookingKeys } from '@/shared/services/booking.service';
import { NavigationBookingService } from '@/shared/services/navigation-booking.service';
import { PageTitleService } from '@/shared/services/page-title.service';
import { ReviewService, reviewKeys } from '@/shared/services/review.service';
import { seasonKeys } from '@/shared/services/season.service';
import { ToastService } from '@/shared/services/toast.service';
import { TrainingsService, trainingKeys } from '@/shared/services/training.service';
import { UsersService, userKeys } from '@/shared/services/user.service';
import { useMutation, useQuery, useQueryClient } from '@/shared/utils/query';
import { TrainingDetailInfoComponent } from '@/trainings/components/training-detail-info/training-detail-info.component';
import { TrainingOptionsComponent } from '@/trainings/components/training-options/training-options.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed, effect, signal } from '@angular/core';
import { FormBuilder } from '@angular/forms'; // Add this import
import { ActivatedRoute, Router } from '@angular/router';
import { useAuth } from '@euricom/angular-shared';
import { startOfToday } from 'date-fns';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';

import { ToastModule } from 'primeng/toast';
import { BookingApproveRejectPanelComponent } from '../../components/booking-approve-reject-panel/booking-approve-reject-panel.component';
import { BookingBookPanelComponent } from '../../components/booking-book-panel/booking-book-panel.component';
import {
  BookingCancelPanelComponent,
  CancelResult,
} from '../../components/booking-cancel-panel/booking-cancel-panel.component';

@Component({
  standalone: true,
  selector: 'tc-booking-detail',
  templateUrl: './booking-detail.component.html',
  imports: [
    CommonModule,
    //PrimeNG
    CardModule,
    ToastModule,
    //Custom
    BookingInfoComponent,
    TrainingDetailInfoComponent,
    TrainingOptionsComponent,
    RequestPanelComponent,
    BookingApproveRejectPanelComponent,
    BookingStartPendingPanelComponent,
    BookingUpdatePendingPanelComponent,
    BookingBookPanelComponent,
    BookingCancelPanelComponent,
    BookingVerifyPanelComponent,
    LoaderComponent,
    BookingHistoryPanelComponent,
    DividerModule,
    ParticipantsListComponent,
    BookingReviewPanelComponent,
    ReviewsComponent,
    ConfirmDialogModule,
  ],
  providers: [BookingsService, TrainingsService, ToastService, UsersService, ReviewService, FormBuilder],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingDetailComponent implements OnInit {
  private readonly id = this._route.snapshot.paramMap.get('id');
  private readonly _queryClient = useQueryClient();
  readonly user = useAuth().user;

  readonly booking = useQuery(
    bookingKeys.byId(this.id),
    () => {
      return this._bookingService.getBookingAsync({ id: this.id ?? '' });
    },
    {
      onError: () => {
        this._router.navigate(['/404']);
      },
      enabled: computed(() => !!this.id),
    },
  );

  readonly userProfile = useQuery(
    computed(() => {
      return {
        queryKey: [userKeys.byEmail(this.booking.data()?.requesterEmail ?? '')],
        queryFn: () => {
          return this._userService.getUserProfileAsync(this.booking.data()?.requesterEmail ?? '');
        },
        enabled: !!this.booking.data()?.requesterEmail,
      };
    }),
  );

  readonly training = useQuery(
    computed(() => {
      return {
        queryKey: trainingKeys.byId(this.booking.data()?.training.id ?? ''),
        queryFn: () => {
          return this._trainingService.getTrainingAsync(this.booking.data()?.training.id);
        },
        enabled: computed(() => this.booking.data()?.training.id !== undefined),
      };
    }),
  );

  selectedOptions = signal<string[]>([]);
  ownBooking = computed(() => this.user()?.email === this.booking.data()?.requesterEmail);
  noPassedOptionSelected = computed(() => {
    if (this.user()?.isAdmin || this.user()?.isHumanResources || this.user()?.isPracticeManager) return true;
    const selected = this.training
      .data()
      ?.options.filter((opt) => this.selectedOptions().includes(opt.id))
      .filter((opt) => this.optionIsPassed(opt.deadline, opt.endDate));

    return selected?.length === 0;
  });

  readonly deleteBookingMutation = useMutation(this._bookingService.deleteBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Removed from plan.');
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);

      if (this.ownBooking()) {
        this._router.navigate(['/bookings']);
      } else {
        this._navigation.back();
      }
    },
  });

  readonly updateBookingMutation = useMutation(this._bookingService.updateBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Saved.');
      this._queryClient.invalidateQueries(bookingKeys.all);

      if (this.ownBooking()) {
        this._router.navigate(['/bookings']);
      } else {
        this._navigation.back();
      }
    },
  });

  readonly requestBookingMutation = useMutation(this._bookingService.requestBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Request is send.');
      this._queryClient.invalidateQueries(bookingKeys.all);

      if (this.ownBooking()) {
        this._router.navigate(['/bookings']);
      } else {
        this._navigation.back();
      }
    },
  });

  readonly approveBookingMutation = useMutation(this._bookingService.approveBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Approved.');
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._navigation.back();
    },
  });

  readonly rejectBookingMutation = useMutation(this._bookingService.rejectBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Rejected.');
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._navigation.back();
    },
  });
  readonly startPendingBookingMutation = useMutation(this._bookingService.startPendingBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Pending started.');
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._queryClient.invalidateQueries(userKeys.byEmail(this.booking.data()?.requesterEmail ?? ''));
      this._navigation.back();
    },
  });
  readonly updatePendingBookingMutation = useMutation(this._bookingService.updatePendingBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Updated.');
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._queryClient.invalidateQueries(userKeys.byEmail(this.booking.data()?.requesterEmail ?? ''));
      this._navigation.back();
    },
  });

  readonly bookBookingMutation = useMutation(this._bookingService.bookBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Booked.');
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
      this._queryClient.invalidateQueries(userKeys.byEmail(this.booking.data()?.requesterEmail ?? ''));
      this._navigation.back();
    },
  });
  readonly verifyBookingMutation = useMutation(this._bookingService.verifyBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Verified.');
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._navigation.back();
    },
  });

  readonly cancelBookingMutation = useMutation(this._bookingService.cancelBookingAsync, {
    onSuccess: () => {
      this._toaster.success('Canceled.');
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(seasonKeys.allAdmin);
      this.ownBooking() ? this._router.navigate(['/bookings']) : this._navigation.back();
    },
  });

  readonly addReviewToTraining = useMutation(this._reviewService.reviewTrainingAsync, {
    onSuccess: () => {
      this._toaster.success('Reviewed.');
      this._queryClient.invalidateQueries(reviewKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.all);
      this._router.navigate(['/bookings']);
    },
  });

  readonly getIcalOfTrainingMutation = useMutation(this._trainingService.getIcalOfTrainingAsync, {
    onSuccess: (data) => {
      this._toaster.success('Successfully downloaded ical file');
      const blob = new Blob([data], { type: 'text/calendar' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'calendar.ics';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
    onError: () => {
      this._toaster.error('Unable to download ical file');
    },
  });

  readonly changeBookingStatusMutation = useMutation(this._bookingService.changeBookingStatusAsync, {
    onSuccess: () => {
      this._toaster.success('Booking status changed successfully.');
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.all);
      // reload page to update the booking status in the url params
      window.location.reload();
    },
    onError: () => {
      this._toaster.error('Failed to change booking status');
    },
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _bookingService: BookingsService,
    private _trainingService: TrainingsService,
    private _userService: UsersService,
    private _reviewService: ReviewService,
    private _router: Router,
    private _toaster: ToastService,
    private _route: ActivatedRoute,
    private _navigation: NavigationBookingService,
  ) {
    effect(
      () => {
        const options = this.booking.data()?.selectedOptions ?? [];
        this.selectedOptions.set(options ?? []);
      },
      { allowSignalWrites: true },
    );
  }
  optionIsPassed(deadline: Date | undefined, endDate: Date | undefined) {
    const today = startOfToday();

    if (
      this.booking.data() &&
      ['Booked', 'Cancelled', 'Rejected', 'Verified'].includes(this.booking.data()?.status ?? '')
    )
      return false;
    if ((deadline && deadline < today) || (endDate && endDate < today)) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Booking detail');
  }

  onDelete() {
    this.deleteBookingMutation.mutate({
      bookingId: this.id ?? '',
    });
  }

  onUpdate() {
    if (!this.id) return;
    this.updateBookingMutation.mutate({
      bookingId: this.id,
      selectedOptions: this.selectedOptions(),
    });
  }

  onRequest(motivation: string) {
    if (!this.id) return;
    this.requestBookingMutation.mutate({
      bookingId: this.id,
      selectedOptions: this.isUpdated() ? this.selectedOptions() : null,
      motivation: motivation,
    });
  }

  onCancel(result: CancelResult) {
    if (!this.id) return;
    this.cancelBookingMutation.mutate({
      bookingId: this.id,
      body: result,
    });
  }

  onChangeStatus(eventPayload: { status: BookingStatus; comment?: string }) {
    if (!this.id) return;
    console.log('test 1');

    if (eventPayload.status === this.booking.data()?.status) {
      this._toaster.warning('Status is already ' + eventPayload.status);
      console.log('test 2');
      return;
    }

    this.changeBookingStatusMutation.mutate({
      bookingId: this.id,
      status: eventPayload.status,
      comment: eventPayload.comment,
    });
  }

  onApprove(comment: string) {
    if (!this.id) return;
    this.approveBookingMutation.mutate({
      bookingId: this.id,
      selectedOptions: this.isUpdated() ? this.selectedOptions() : null,
      comment: comment,
    });
  }

  onReject(comment: string) {
    if (!this.id) return;
    this.rejectBookingMutation.mutate({
      bookingId: this.id,
      selectedOptions: this.selectedOptions(),
      comment: comment,
    });
  }

  onStartPending(comment?: string) {
    if (!this.id) return;
    this.startPendingBookingMutation.mutate({
      bookingId: this.id,
      selectedOptions: this.isUpdated() ? this.selectedOptions() : null,
      comment: comment,
    });
  }

  onUpdatePending(comment: string) {
    if (!this.id) return;
    this.updatePendingBookingMutation.mutate({
      bookingId: this.id,
      selectedOptions: this.isUpdated() ? this.selectedOptions() : null,
      comment: comment,
    });
  }
  onBook(comment: string) {
    if (!this.id) return;
    this.bookBookingMutation.mutate({
      bookingId: this.id,
      selectedOptions: this.isUpdated() ? this.selectedOptions() : null,
      comment: comment,
    });
  }

  onVerify() {
    if (!this.id) return;
    this.verifyBookingMutation.mutate(this.id);
  }

  onReview(review: ReviewResult) {
    const training = this.training.data();
    if (!training || !training.id) return;
    this.addReviewToTraining.mutate({
      id: training.id,
      message: review.message,
      rating: review.rating,
    });
  }

  onGetIcal() {
    this.getIcalOfTrainingMutation.mutate({ id: this.training.data()?.id ?? '', options: this.selectedOptions() });
  }

  isUpdated = () => {
    const booking = this.booking.data();
    if (!booking) {
      return false;
    }
    const selectedOptions = this.selectedOptions();
    return booking.selectedOptions.sort().join() !== selectedOptions.sort().join();
  };

  isPassed() {
    const startDate = this.training.data()?.startDate;
    if (!startDate) return false;
    return startDate <= new Date();
  }
  showRequestPanel() {
    if (
      this.user()?.isPracticeManager ||
      this.user()?.isHumanResources ||
      this.user()?.isAdmin ||
      this.user()?.isCatalogResponsible
    )
      return true;
    return this.training.data()?.isEnabled;
  }
}
