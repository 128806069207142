import { ToastService } from '@/shared/services/toast.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HandlerResult, HandlerResultStatus } from '@euricom/angular-training-catalog-api/models';

export const handleHandlerResultToast = (result: HandlerResult, toaster: ToastService) => {
  switch (result.status) {
    case HandlerResultStatus.Success:
      toaster.success(result.message || 'Operation successful');
      break;
    case HandlerResultStatus.NotFound:
    case HandlerResultStatus.Conflict:
      toaster.warning(result.message || 'Unexpected condition');
      break;
    case HandlerResultStatus.Failed:
    case HandlerResultStatus.Forbidden:
      toaster.error(result.message || 'Operation failed');
      break;
    default:
      toaster.warning('Unexpected result');
  }
};

export const getBulkMailConsultantsForm = () => {
  return new FormGroup({
    seasonYear: new FormControl<string | null>(null, [Validators.required]),
  });
};
