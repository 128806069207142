<p-table
  #trainingFollowUpDetailTable
  [value]="bookings"
  class="max-w-full min-w-full"
  styleClass="p-datatable-sm"
  (sortFunction)="customSort($event)"
  [(selection)]="selectedBookings"
  [customSort]="true"
  [sortOrder]="tableSort?.order ?? -1"
  [sortField]="tableSort?.sort"
  [rowHover]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="hidden md:table-cell">
        <div class="text-center">
          <p-tableHeaderCheckbox (click)="onSelect()" name="selectAll" />
        </div>
      </th>
      <th style="width: 20%; max-width: 20%" pSortableColumn="requesterName" (click)="setSort('requesterName')">
        <div class="flex items-center">
          <div class="w-fit">Name</div>
          <p-sortIcon field="requesterName" />
        </div>
      </th>
      <th pSortableColumn="status" (click)="setSort('status')" class="hidden md:table-cell">
        <div class="flex items-center">
          <div>Status</div>
          <p-sortIcon class="hidden sm:block" field="status" />
        </div>
      </th>
      <th>
        <div style="width: 33%; max-width: 33%">Options</div>
      </th>
      <th>
        <div style="width: 33%; max-width: 33%" class="pl-2">Actions</div>
      </th>
      <th>
        <div style="width: auto; max-width: auto">History</div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-booking>
    <tr class="cursor-pointer overflow-hidden" (click)="onClickBooking(booking.id)">
      <td class="hidden md:table-cell" (click)="stopPropagation($event)">
        <div class="text-center">
          <p-tableCheckbox (click)="onSelect()" [value]="booking" name="select" />
        </div>
      </td>
      <td class="w-min md:w-fit md:max-w-full">
        <div class="line-clamp-2 md:line-clamp-none mr-1 md:m-0">
          <p (click)="stopPropagation($event)" class="cursor-text w-fit px-3">{{ booking.requesterName }}</p>

          <p (click)="stopPropagation($event)" class="text-xs cursor-text w-fit px-3">{{ booking.requesterEmail }}</p>
        </div>
        <p class="md:hidden pl-2 truncate ... max-w-[90%]" [class]="booking.status === 'Verified' ? 'text-accent' : ''">
          - {{ booking.status | fStatus }}
        </p>
      </td>
      <td class="hidden md:table-cell" [class]="booking.status === 'Verified' ? 'text-accent' : ''">
        {{ booking.status | fStatus }}
      </td>
      <td>
        <ul style="list-style-type: disc">
          <li *ngFor="let option of getSelectedOptions(booking.selectedOptions)">
            {{ option.name }}
          </li>
        </ul>
      </td>
      <td (click)="stopPropagation($event)">
        <div class="grid md:grid-cols-[14rem_1fr] justify-center text-center md:justify-start md:text-left">
          <p-button
            class="w-fit"
            *ngIf="booking.status === 'Approved'"
            label="Start Pending Booking"
            [link]="true"
            (click)="onStartPending(booking.id)"
          ></p-button>
          <p-button
            class="w-fit"
            *ngIf="booking.status === 'PendingBooking'"
            label="Update Pending Booking"
            [link]="true"
            (click)="onUpdate(booking.id)"
          ></p-button>
          <p-button
            class="w-fit"
            *ngIf="['Approved', 'PendingBooking'].includes(booking.status)"
            label="Book"
            [link]="true"
            (click)="onBook(booking.id)"
          ></p-button>
          <p-button
            class="w-fit"
            *ngIf="['Booked', 'Verified'].includes(booking.status)"
            [label]="booking.status === 'Verified' ? 'Unverify' : 'Verify'"
            [link]="true"
            (click)="onVerify(booking.id)"
          ></p-button>
          <p-button
            class="w-fit"
            *ngIf="booking.status === 'Booked'"
            label="Cancel"
            [link]="true"
            (click)="onCancel(booking.id)"
          ></p-button>
        </div>
      </td>
      <td (click)="stopPropagation($event)">
        <p-button
          [loading]="bookingHistoryLoading()[booking.id]"
          [outlined]="true"
          size="small"
          type="button"
          icon="pi pi-history"
          (onClick)="onShowHistory(booking?.id)"
          class="p-button-sm"
        ></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-confirmDialog
  #cd
  key="dialogForStartPendingAndUpdatePending"
  rejectButtonStyleClass="p-button-outlined p-button-sm"
  acceptButtonStyleClass="p-button-sm"
>
  <ng-template pTemplate="message">
    <div class="min-w-[35rem]">
      <div class="flex content-center">
        <p *ngIf="cd.confirmation?.acceptLabel?.includes('Book')" class="mb-4">Deze comment wordt verstuurd op mail.</p>
        <p *ngIf="!cd.confirmation?.acceptLabel?.includes('Book')" class="mb-4">
          Deze comment wordt niet op mail verstuurd, maar wel toegevoegd aan de booking.
        </p>
      </div>
      <div *ngIf="cd.confirmation?.acceptLabel?.includes('Book')" class="border rounded text-sm p-2 m-2">
        <ul class="list-disc ml-8">
          <li *ngFor="let comment of comments()">
            <p>{{ comment.date | fDate }} - {{ comment.message }}</p>
          </li>
        </ul>
      </div>
      <form [formGroup]="form">
        <label class="flex gap-1" for="motivation"
          >{{ cd.confirmation?.acceptLabel?.includes('Book') ? 'Extra comment:' : 'Comment:' }}
          <p *ngIf="!cd.confirmation?.acceptLabel?.includes('Start')" class="text-error">*</p></label
        >

        <textarea
          rows="3"
          id="motivation"
          name="motivation"
          pInputTextarea
          class="w-full"
          [formControl]="form.controls.comment"
        ></textarea>
        <div class="text-error">
          <p
            *ngIf="
              !cd.confirmation?.acceptLabel?.includes('Start') &&
              form.controls.comment.dirty &&
              (form.controls.comment.hasError('required') || form.controls.comment.hasError('whitespace'))
            "
          >
            Comment is required.
          </p>
          <p *ngIf="form.controls.comment.dirty && form.controls.comment.hasError('maxlength')" class="text-error">
            Comment is too long.
          </p>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="cd.reject()"></button>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      [disabled]="
        !cd.confirmation?.acceptLabel?.includes('Start') &&
        (form.controls.comment.hasError('required') ||
          form.controls.comment.hasError('whitespace') ||
          form.controls.comment.hasError('maxlength'))
      "
      [label]="cd.confirmation?.acceptLabel ?? 'Send'"
      (click)="cd.accept()"
    ></button>
  </ng-template>
</p-confirmDialog>
