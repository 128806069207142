import { SeasonAdminHeaderComponent } from '@/seasons/components/season-admin-header/season-admin-header.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { EmailService } from '@/shared/services/email.service';
import { PageTitleService } from '@/shared/services/page-title.service';
import { SearchParamsService } from '@/shared/services/search-param.service';
import { seasonKeys, SeasonsService } from '@/shared/services/season.service';
import { ToastService } from '@/shared/services/toast.service';
import { useMutation, useQuery } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, OnInit, signal, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { useAuth } from '@euricom/angular-shared';
import { BulkMailInfoDto, HandlerResult } from '@euricom/angular-training-catalog-api/models';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { StyleClassModule } from 'primeng/styleclass';
import { Table, TableModule } from 'primeng/table';
import { firstValueFrom } from 'rxjs';
import { getBulkMailConsultantsForm, handleHandlerResultToast } from './helpers';
type SortTypes =
  | 'year'
  | 'startDate'
  | 'endDate'
  | 'creditMultiplier'
  | 'nrOfTrainings'
  | 'nrOfBookings'
  | 'totalBookingCredits';

@Component({
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    FormatDisplayPipe,
    DividerModule,
    LoaderComponent,
    TableModule,
    CommonModule,
    StyleClassModule,
    FormatDatePipe,
    SeasonAdminHeaderComponent,
    ConfirmDialogModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
  ],
  providers: [SeasonsService, SearchParamsService, EmailService],
  selector: 'tc-admin-seasons',
  templateUrl: './seasons-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSeasonsComponent implements OnInit {
  readonly seasons = useQuery(seasonKeys.allAdmin, () => {
    return this._seasonsService.getSeasonsAdminAsync();
  });
  readonly sortedSeasons = computed(() => this.seasons.data()?.sort((a, b) => b.year - a.year));
  @ViewChild('seasonTable') seasonTable: Table | undefined;

  readonly user = useAuth().user;
  readonly isAdmin = computed(() => this.user()?.isAdmin ?? false);
  bulkMailForm = getBulkMailConsultantsForm(); // Same form used dynamically
  dialogConfig: {
    header: string;
    message: string;
    icon?: string;
    withForm?: boolean;
    acceptCallback?: () => void;
  } | null = null;

  readonly searchParams = this._searchParamService.getSearchParams<{
    sort: SortTypes | undefined;
    order: string | undefined;
  }>();
  readonly sort = signal<SortTypes | undefined>(this.searchParams.sort);
  readonly sortOrder = parseInt(this.searchParams.order ?? '-1');

  // Mutation for sending reminder emails
  readonly bulkMailConsultantsMutation = useMutation(
    (seasonYear: string) => {
      const dto: BulkMailInfoDto = { seasonYear };
      return firstValueFrom(this._emailService.remindConsultantsAboutAddedBookingsAsync(dto));
    },
    {
      onSuccess: (result: HandlerResult) => {
        handleHandlerResultToast(result, this._toaster);
      },
      onError: (error: HandlerResult) => {
        this._toaster.error(error.message || 'Failed to send reminder emails');
      },
    },
  );

  readonly bulkMailHrMutation = useMutation(
    (seasonYear: string) => {
      const dto: BulkMailInfoDto = { seasonYear };
      return firstValueFrom(this._emailService.remindHrAboutApprovedOrPendingBookingsAsync(dto));
    },
    {
      onSuccess: (result: HandlerResult) => {
        handleHandlerResultToast(result, this._toaster);
      },
      onError: (error: HandlerResult) => {
        this._toaster.error(error.message || 'Failed to send reminder emails');
      },
    },
  );

  readonly bulkMailPracticeManagersMutation = useMutation(
    (seasonYear: string) => {
      const dto: BulkMailInfoDto = { seasonYear };
      return firstValueFrom(this._emailService.remindPracticeManagersAboutRequestedBookingsAsync(dto));
    },
    {
      onSuccess: (result: HandlerResult) => {
        handleHandlerResultToast(result, this._toaster);
      },
      onError: (error: HandlerResult) => {
        this._toaster.error(error.message || 'Failed to send reminder emails');
      },
    },
  );

  constructor(
    private _pageTitleService: PageTitleService,
    private _seasonsService: SeasonsService,
    private _router: Router,
    private _searchParamService: SearchParamsService,
    private _confirmationService: ConfirmationService,
    private _toaster: ToastService,
    private _emailService: EmailService,
  ) {
    effect(() => {
      const order = this.seasonTable?.sortOrder;
      const queryParams = {
        sort: this.sort(),
        order: order ?? this.sortOrder.toString(),
      };
      if (this.sort()) this._searchParamService.setQueryParams(queryParams);
    });
  }

  setSort(sortValue: SortTypes) {
    this.sort.set(undefined);
    this.sort.set(sortValue);
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Seasons admin');
  }

  showBulkMailConfirmDialog(config: {
    header: string;
    message: string;
    acceptCallback: () => void;
    icon?: string;
    withForm?: boolean;
  }) {
    // Automatically set the selected year to the latest one available in `sortedSeasons`
    const latestYear = this.sortedSeasons()?.[0]?.year;
    if (config.withForm && latestYear) {
      this.bulkMailForm.patchValue({ seasonYear: latestYear.toString() });
    }

    this.dialogConfig = config;
    this._confirmationService.confirm({
      header: config.header,
      key: 'bulkMailDialog', // Refers to the same confirmDialog in the template
      accept: config.acceptCallback,
    });
  }

  onClickAddSeason() {
    this._router.navigate(['/admin/seasons/add']);
  }

  onClickSeason(seasonId: string) {
    this._router.navigate(['/admin/seasons/', seasonId]);
  }

  // Purpose: Triggers a bulk email to users with bookings having the status 'Added',
  // asking if those bookings are still relevant for the selected season.
  onBulkMailConsultants() {
    this.showBulkMailConfirmDialog({
      header: 'Send Reminders to Consultants',
      message: `Do you want to send bulk mail reminder to all Consultants 
                that have bookings with status "Added" to ask if they 
                are still relevant before the season ends?`,
      withForm: true, // Enables form UI
      acceptCallback: () => {
        const selectedYear = this.bulkMailForm.get('seasonYear')?.value;
        if (selectedYear) {
          this.bulkMailConsultantsMutation.mutate(selectedYear.toString());
        }
      },
    });
  }

  // Purpose: Triggers a bulk email to HR about all trainings with the status 'Approved' or 'Pending Booking'.
  onBulkMailHr() {
    this.showBulkMailConfirmDialog({
      header: 'Send Reminders to HR',
      message: `Are you sure you want to send an email to HR
                with all trainings in "Approved" and "Pending Booking" status?`,
      withForm: true,
      acceptCallback: () => {
        const selectedYear = this.bulkMailForm.get('seasonYear')?.value;
        if (selectedYear) {
          this.bulkMailHrMutation.mutate(selectedYear.toString());
        }
      },
    });
  }

  // Purpose: Triggers a bulk email to Responsibles about all trainings with the status 'Requested' for the selected season.
  onBulkMailPracticeManagers() {
    this.showBulkMailConfirmDialog({
      header: 'Send Reminders to Practice Managers',
      message: 'Are you sure you want to send reminders to all Practice Managers about requested trainings?',
      withForm: true,
      acceptCallback: () => {
        const selectedYear = this.bulkMailForm.get('seasonYear')?.value;
        if (selectedYear) {
          this.bulkMailPracticeManagersMutation.mutate(selectedYear.toString());
        }
      },
    });
  }
}
