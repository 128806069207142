<div class="flex flex-col lg:flex-row gap-4 p-4 min-h-full w-full">
  <ng-container *ngFor="let item of manageItems">
    <p-card class="w-full overflow-hidden">
      <h2 class="text-xl font-bold mb-4 text-blue-600">{{ item.title }}</h2>
      <p-button
        size="small"
        [label]="'Manage ' + item.title"
        icon="pi pi-external-link"
        class="p-button-outlined"
        (click)="openNewTab(item)"
      ></p-button>
    </p-card>
  </ng-container>
</div>
