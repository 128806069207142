/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { TrainingCatalogApiConfiguration } from '../training-catalog-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { KnowledgeDomainDto } from '../models/knowledge-domain-dto';
import { KnowlegdeDomainAddDto } from '../models/knowlegde-domain-add-dto';

@Injectable({ providedIn: 'root' })
export class KnowledgeDomainsService extends BaseService {
  constructor(config: TrainingCatalogApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllKnowledgeDomains()` */
  static readonly GetAllKnowledgeDomainsPath = '/api/KnowledgeDomains';

  /**
   * Retrieves a list of knowledge domains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllKnowledgeDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllKnowledgeDomains$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<KnowledgeDomainDto>>> {
    const rb = new RequestBuilder(this.rootUrl, KnowledgeDomainsService.GetAllKnowledgeDomainsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<KnowledgeDomainDto>>;
      })
    );
  }

  /**
   * Retrieves a list of knowledge domains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllKnowledgeDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllKnowledgeDomains(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<KnowledgeDomainDto>> {
    return this.getAllKnowledgeDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KnowledgeDomainDto>>): Array<KnowledgeDomainDto> => r.body)
    );
  }

  /** Path part for operation `addKnowledgeDomain()` */
  static readonly AddKnowledgeDomainPath = '/api/KnowledgeDomains';

  /**
   * Adds a new knowledge domain.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addKnowledgeDomain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addKnowledgeDomain$Response(
    params?: {
  
    /**
     * The knowledge domain data to be added.
     */
    body?: KnowlegdeDomainAddDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<KnowledgeDomainDto>> {
    const rb = new RequestBuilder(this.rootUrl, KnowledgeDomainsService.AddKnowledgeDomainPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KnowledgeDomainDto>;
      })
    );
  }

  /**
   * Adds a new knowledge domain.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addKnowledgeDomain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addKnowledgeDomain(
    params?: {
  
    /**
     * The knowledge domain data to be added.
     */
    body?: KnowlegdeDomainAddDto
    },
    context?: HttpContext
  ): Observable<KnowledgeDomainDto> {
    return this.addKnowledgeDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<KnowledgeDomainDto>): KnowledgeDomainDto => r.body)
    );
  }

  /** Path part for operation `deleteKnowledgeDomain()` */
  static readonly DeleteKnowledgeDomainPath = '/api/KnowledgeDomains/{id}';

  /**
   * Deletes a knowledge domain by its unique identifier.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteKnowledgeDomain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteKnowledgeDomain$Response(
    params: {

    /**
     * The unique identifier of the knowledge domain to delete.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, KnowledgeDomainsService.DeleteKnowledgeDomainPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a knowledge domain by its unique identifier.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteKnowledgeDomain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteKnowledgeDomain(
    params: {

    /**
     * The unique identifier of the knowledge domain to delete.
     */
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteKnowledgeDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
