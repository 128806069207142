import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrainingCatalogApiSettings } from '@euricom/angular-training-catalog-api';
import { BulkMailInfoDto, HandlerResult } from '@euricom/angular-training-catalog-api/models';

import { EmailService as RootEmailService } from '@euricom/angular-training-catalog-api/services';
import { Observable } from 'rxjs';

@Injectable()
export class EmailService extends RootEmailService {
  constructor(config: TrainingCatalogApiSettings, http: HttpClient) {
    super({ rootUrl: config.baseUrl }, http);
  }

  // Method to directly use the root service method
  // If you want to add additional logic or error handling
  // Method to send reminder to consultants using Observable
  remindConsultantsAboutAddedBookingsAsync(dto: BulkMailInfoDto): Observable<HandlerResult> {
    return this.remindConsultantsAboutAddedBookings({
      body: { seasonYear: dto.seasonYear },
    });
  }

  remindHrAboutApprovedOrPendingBookingsAsync(dto: BulkMailInfoDto): Observable<HandlerResult> {
    return this.remindHrAboutApprovedOrPendingBookings({
      body: { seasonYear: dto.seasonYear },
    });
  }

  remindPracticeManagersAboutRequestedBookingsAsync(dto: BulkMailInfoDto): Observable<HandlerResult> {
    return this.remindPracticeManagersAboutRequestedBookings({
      body: { seasonYear: dto.seasonYear },
    });
  }
}
