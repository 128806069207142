import { BookingForTrainingFollowUp } from '@/shared/models/booking-for-training-follow-up';
import { Comment } from '@/shared/models/comment';
import { TrainingOption } from '@/shared/models/training-option';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { FormatDisplayPipe } from '@/shared/pipes/format-display-pipe';
import { StatusDisplayPipe } from '@/shared/pipes/status-display-pipe';
import { NoWhitespaceValidatorDirective } from '@/shared/validators/no-white-space.validator';
import {
  SortTypesTrainingFollowUpDetailTable,
  TrainingFollowUpDetailTableSort,
} from '@/trainings/pages/training-follow-up-detail/training-follow-up-detail.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, SortEvent } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { StyleClassModule } from 'primeng/styleclass';
import { Table, TableModule } from 'primeng/table';
import { TrainingOptionsComponent } from '../training-options/training-options.component';

@Component({
  selector: 'tc-training-follow-up-detail-table',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    FormsModule,
    FormatDisplayPipe,
    ReactiveFormsModule,
    ConfirmDialogModule,
    InputTextareaModule,
    StyleClassModule,
    FormatDatePipe,
    StatusDisplayPipe,
    TrainingOptionsComponent,
    AvatarModule,
  ],
  templateUrl: './training-follow-up-detail-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingFollowUpDetailTableComponent {
  @ViewChild('trainingFollowUpDetailTable') trainingFollowUpDetailTable: Table | undefined;
  @Input() bookings: BookingForTrainingFollowUp[] = [];
  @Input() options: TrainingOption[] = [];
  @Input() tableSort?: TrainingFollowUpDetailTableSort;
  @Input() selectedBookings: BookingForTrainingFollowUp[] = [];

  @Output() sortChanges = new EventEmitter<TrainingFollowUpDetailTableSort>();

  @Output() verify = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<string>();
  @Output() book = new EventEmitter<{ id: string; comment: string }>();
  @Output() startPending = new EventEmitter<{ id: string; comment?: string }>();
  @Output() update = new EventEmitter<{ id: string; comment: string }>();
  @Output() selectBooking = new EventEmitter<BookingForTrainingFollowUp[]>();
  @Output() showHistory = new EventEmitter<string>();

  bookingHistoryLoading = signal<{ [bookingId: string]: boolean }>({});
  comments = signal<Comment[]>([]);
  form = new FormGroup({
    comment: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.maxLength(255)],
    }),
  });

  constructor(private _confirmationService: ConfirmationService, private _router: Router) {}

  initializeForm() {
    this.form = new FormGroup({
      comment: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.maxLength(255)],
      }),
    });
  }

  onSelect() {
    this.selectBooking.emit(this.selectedBookings);
  }

  getSelectedOptions(ids: string[]) {
    return this.options.filter((o) => ids.includes(o.id));
  }

  onVerify(id: string) {
    const bookingToVerify = this.bookings.find((b) => b.id === id);

    if (bookingToVerify) {
      bookingToVerify.status === 'Verified'
        ? (bookingToVerify.status = 'Booked')
        : (bookingToVerify.status = 'Verified');
    }
    this.verify.emit(id);
  }

  onBook(id: string) {
    const bookingToBook = this.bookings.find((b) => b.id === id);

    this.comments.set(bookingToBook?.comments ?? []);

    this.form.controls.comment.addValidators([Validators.required, NoWhitespaceValidatorDirective.noWhitespace()]);

    this._confirmationService.confirm({
      header: 'Book booking',
      key: 'dialogForStartPendingAndUpdatePending',
      acceptLabel: 'Book booking',
      message: bookingToBook?.comments.toString(),
      reject: () => {
        this.initializeForm();
      },
      accept: () => {
        if (bookingToBook) {
          bookingToBook.status = 'Booked';
        }
        this.book.emit({ id, comment: this.form.controls.comment.value });
        this.initializeForm();
      },
    });
  }
  onStartPending(id: string) {
    const bookingToStartPending = this.bookings.find((b) => b.id === id);

    this._confirmationService.confirm({
      header: 'Start Pending booking',
      key: 'dialogForStartPendingAndUpdatePending',
      acceptLabel: 'Start pending booking',
      reject: () => {
        this.initializeForm();
      },
      accept: () => {
        if (bookingToStartPending) {
          bookingToStartPending.status = 'PendingBooking';
        }

        this.startPending.emit({ id, comment: this.form.controls.comment.value ?? undefined });
        this.initializeForm();
      },
    });
  }
  onUpdate(id: string) {
    this.form.controls.comment.addValidators([Validators.required, NoWhitespaceValidatorDirective.noWhitespace()]);

    this._confirmationService.confirm({
      header: 'Update Pending booking',
      key: 'dialogForStartPendingAndUpdatePending',
      acceptLabel: 'Update pending booking',
      reject: () => {
        this.initializeForm();
      },
      accept: () => {
        this.update.emit({ id, comment: this.form.controls.comment.value });
        this.initializeForm();
      },
    });
  }

  onCancel(id: string) {
    this._confirmationService.confirm({
      message: 'Are you sure you want to cancel this booking?',
      header: 'Confirmation cancel',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.canceling(id),
    });
  }

  canceling(id: string) {
    this.bookings = this.bookings.filter((b) => b.id !== id);
    this.cancel.emit(id);
  }

  customSort(event: SortEvent) {
    if (!event.data || !event.field) return;

    event.data.sort((data1, data2) => {
      const value1 = this.getPropertyValue(data1, event.field);
      const value2 = this.getPropertyValue(data2, event.field);

      if (value1 == null && value2 != null) return -1;
      if (value1 != null && value2 == null) return 1;
      if (value1 == null && value2 == null) return 0;

      if (typeof value1 === 'boolean' && typeof value2 === 'boolean') {
        if (value1 === value2) return 0;
        return value1 ? -1 : 1;
      }

      if (typeof value1 === 'string' && typeof value2 === 'string') {
        return value1.localeCompare(value2);
      }

      return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
    });

    if (event.order === undefined || event.order === 1) {
      return event.data;
    } else {
      return event.data.reverse();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getPropertyValue(object: any, field = '') {
    const fields = field.split('.');
    let value = object;

    for (const f of fields) {
      value = value[f];
    }
    return value;
  }

  setSort(sortValue: SortTypesTrainingFollowUpDetailTable) {
    const order = this.trainingFollowUpDetailTable?.sortOrder ?? -1;
    this.sortChanges.emit({ sort: sortValue, order: order });
  }

  onClickBooking(id: string) {
    this._router.navigate(['/follow-up/bookings', id]);
  }
  stopPropagation(e: MouseEvent) {
    e.stopPropagation();
  }

  onShowHistory(bookingId: string) {
    this.bookingHistoryLoading.update((state) => ({
      ...state,
      [bookingId]: true,
    }));

    this.showHistory.emit(bookingId);
  }

  completeHistoryLoading(bookingId: string) {
    this.bookingHistoryLoading.update((state) => ({
      ...state,
      [bookingId]: false,
    }));
  }
}
