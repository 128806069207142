import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { ParticipantsListComponent } from '@/shared/components/participants-list/participants-list.component';
import { RequestPanelComponent } from '@/shared/components/request-panel/request-panel.component';
import { ReviewsComponent } from '@/shared/components/reviews/reviews.component';
import { User } from '@/shared/models/user';
import { BookingsService, bookingKeys } from '@/shared/services/booking.service';
import { PageTitleService } from '@/shared/services/page-title.service';
import { ToastService } from '@/shared/services/toast.service';
import { TrainingsService, trainingKeys } from '@/shared/services/training.service';
import { UsersService, userKeys } from '@/shared/services/user.service';
import { useMutation, useQuery, useQueryClient } from '@/shared/utils/query';
import { TrainingAddBookingComponent } from '@/trainings/components/training-add-booking/training-add-booking.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, WritableSignal, computed, effect, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { useAuth } from '@euricom/angular-shared';
import { startOfToday } from 'date-fns';
import { CardModule } from 'primeng/card';
import { TrainingDetailInfoComponent } from '../../components/training-detail-info/training-detail-info.component';
import { TrainingOptionsComponent } from '../../components/training-options/training-options.component';
import { getDefaultSelectableOptions } from './helpers';

@Component({
  selector: 'tc-training-detail.ts',
  standalone: true,
  imports: [
    CommonModule,
    // primeNG
    CardModule,
    CommonModule,
    // custom
    TrainingDetailInfoComponent,
    TrainingOptionsComponent,
    RequestPanelComponent,
    TrainingAddBookingComponent,
    LoaderComponent,
    ParticipantsListComponent,
    ReviewsComponent,
  ],
  providers: [TrainingsService, BookingsService, ToastService, UsersService],
  templateUrl: './training-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingDetailComponent implements OnInit {
  private readonly id = this._route.snapshot.paramMap.get('id');
  private readonly user = useAuth().user;
  readonly _queryClient = useQueryClient();

  readonly forUser: WritableSignal<User | null> = signal(null);

  readonly training = useQuery(
    trainingKeys.byId(this.id),
    () => {
      return this._trainingService.getTrainingAsync(this.id ?? '');
    },
    {
      onSuccess: (training) => {
        if (!this.user()?.isAdmin) {
          // Redirect to 404 if the training is invalid
          if (training.isEnabled === undefined || (training.endDate ?? new Date()) < startOfToday()) {
            this._router.navigate(['/404']);
          }
        }
      },
      onError: () => {
        this._router.navigate(['/404']);
      },
      enabled: computed(() => !!this.id),
    },
  );

  readonly users = useQuery(
    userKeys.all,
    () => {
      return this._usersService.getUsersAsync();
    },
    { enabled: computed(() => (this.user()?.isAdmin || this.user()?.isPracticeManager) ?? false) },
  );

  readonly addBookingMutation = useMutation(this._bookingService.addBookingAsync, {
    onSuccess: (booking) => {
      this._toaster.success('Added to plan.');
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.byId(this.id));

      if (this.user()?.email === booking.requesterEmail) {
        this._router.navigate(['/bookings', booking.id]);
      } else {
        this._router.navigate(['/follow-up/bookings', booking.id]);
      }
    },
  });

  readonly requestBookingMutation = useMutation(this._bookingService.addAndRequestBookingAsync, {
    onSuccess: (booking) => {
      this._toaster.success('Request is send.');
      this._queryClient.invalidateQueries(bookingKeys.all);
      this._queryClient.invalidateQueries(trainingKeys.byId(this.id));

      if (this.user()?.email === booking.requesterEmail) {
        this._router.navigate(['/bookings', booking.id]);
      } else {
        this._router.navigate(['/follow-up/bookings', booking.id]);
      }
    },
  });

  readonly getIcalOfTrainingMutation = useMutation(this._trainingService.getIcalOfTrainingAsync, {
    onSuccess: (data) => {
      this._toaster.success('Successfully downloaded ical file');
      const blob = new Blob([data], { type: 'text/calendar' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'calendar.ics';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
    onError: () => {
      this._toaster.error('Unable to download ical file');
    },
  });

  readonly selectedOptions = signal<undefined | string[]>(undefined);
  readonly hasOptionSelected = computed(() => {
    const selectedOptions = this.selectedOptions();
    if (!selectedOptions) return false;
    return selectedOptions.length > 0;
  });
  noPassedOptionSelected = computed(() => {
    if (this.user()?.isAdmin) return true;
    const selected = this.training
      .data()
      ?.options.filter((opt) => this.selectedOptions()?.includes(opt.id))
      .filter((opt) => this.optionIsPassed(opt.deadline, opt.endDate));

    return selected?.length === 0;
  });

  constructor(
    private _pageTitleService: PageTitleService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _toaster: ToastService,
    private _trainingService: TrainingsService,
    private _bookingService: BookingsService,
    private _usersService: UsersService,
  ) {
    // start with default options from training
    effect(
      () => {
        // start with default options from training
        this.selectedOptions.set(getDefaultSelectableOptions(this.training.data).map((option) => option.id));
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Detail');
  }
  optionIsPassed(deadline: Date | undefined, endDate: Date | undefined) {
    const today = startOfToday();

    if ((deadline && deadline < today) || (endDate && endDate < today)) {
      return true;
    }
    return false;
  }

  onSelectedOptionsChange(selectedOptions: string[]) {
    this.selectedOptions.set(selectedOptions);
  }

  onAddBooking() {
    this.addBookingMutation.mutate({
      selectedOptions: this.selectedOptions(),
      trainingId: this.training.data()?.id ?? '',
      addForUser: this.forUser() ?? undefined,
    });
  }

  onRequestTraining(motivation: string) {
    this.requestBookingMutation.mutate({
      trainingId: this.training.data()?.id ?? '',
      selectedOptions: this.selectedOptions() ?? [],
      motivation: motivation,
      addForUser: this.forUser() ?? undefined,
    });
  }

  onGetIcal() {
    this.getIcalOfTrainingMutation.mutate({
      id: this.training.data()?.id ?? '',
      options: this.selectedOptions() ?? [],
    });
  }

  isPassedDeadline() {
    if (this.user()?.isPracticeManager || this.user()?.isHumanResources || this.user()?.isAdmin) return false;

    const today = startOfToday();

    const deadline = this.training.data()?.deadline;
    const endDate = this.training.data()?.endDate;

    //if deadline is set check if today is passed deadline, else check if today is passed end date
    if (deadline) {
      return today > deadline;
    } else if (endDate) {
      return today > endDate;
    }
    return false;
  }

  isAlreadyBookedByUser() {
    if (
      this.user()?.isPracticeManager ||
      this.user()?.isHumanResources ||
      this.user()?.isAdmin ||
      this.training.data()?.allowMultipleBookings
    )
      return false;
    return this.training.data()?.isAlreadyBookedBy?.includes(this.forUser()?.name ?? this.user()?.name ?? '') ?? false;
  }

  showRequestPanel() {
    if (
      this.user()?.isPracticeManager ||
      this.user()?.isHumanResources ||
      this.user()?.isAdmin ||
      this.user()?.isCatalogResponsible
    )
      return true;
    return this.training.data()?.isEnabled;
  }
}
