import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { useAuth } from '@euricom/angular-shared';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { distinctUntilChanged, skip } from 'rxjs';

@Component({
  selector: 'tc-booking-filter-header',
  standalone: true,
  imports: [CommonModule, InputTextModule, ReactiveFormsModule, ButtonModule, TooltipModule],
  templateUrl: './booking-filter-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingFilterHeaderComponent {
  readonly user = useAuth().user();
  @Input()
  set searchValue(value: string) {
    this.searchValueControl.setValue(value);
  }

  @Input() bookingsSelected?: number;

  @Input({ required: true }) isSelectedSeasonActiveSeason!: boolean;

  @Input({ required: true }) isExporting: boolean = false;

  @Output() bookBooking = new EventEmitter();

  @Output() cancelBooking = new EventEmitter();

  @Output() verifyBooking = new EventEmitter();

  @Output() exportBookings = new EventEmitter();

  @Output() deleteAddedBookings = new EventEmitter();

  // @Output() sendMailToConsultants = new EventEmitter();

  @Output() readonly searchValueControl = new FormControl<string>('', { nonNullable: true });

  @Output() readonly searchValueChange = this.searchValueControl.valueChanges.pipe(skip(1), distinctUntilChanged());
}
