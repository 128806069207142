<p-divider align="left">
  <b>Cost</b>
</p-divider>

<div class="grid grid-cols-3 gap-4 my-1">
  <div class="flex flex-col w-full">
    <label for="cost">Cost <span class="text-error">*</span></label>
    <input
      id="cost"
      name="cost"
      type="number"
      class="p-inputtext-sm w-full"
      min="0"
      step="1"
      pInputText
      [formControl]="form.controls.cost"
      (input)="onCalculateCredits()"
    />
    <p *ngIf="form.controls.cost.dirty && form.controls.cost.hasError('required')" class="text-error">
      Cost is required
    </p>
    <p *ngIf="form.controls.cost.dirty && form.controls.cost.hasError('min')" class="text-error">
      Cost should be positive
    </p>
    <p *ngIf="form.controls.cost.dirty && form.controls.cost.hasError('pattern')" class="text-error">
      Cost doesn't allow commas
    </p>
  </div>
  <div class="flex flex-col w-full" *ngIf="isTrainingNotSelfPaced">
    <label for="halfDays">Half Days</label>
    <input
      id="halfDays"
      name="halfDays"
      type="number"
      min="0"
      class="p-inputtext-sm w-full"
      pInputText
      [formControl]="form.controls.halfDays"
      (input)="onCalculateCredits()"
    />
    <p *ngIf="form.controls.halfDays.dirty && form.controls.halfDays.hasError('min')" class="text-error">
      Half days should be positive
    </p>
    <p *ngIf="form.controls.halfDays.dirty && form.controls.halfDays.hasError('pattern')" class="text-error">
      Half days doesn't allow commas
    </p>
  </div>
  <div class="flex flex-col w-full text-secondary">
    <label for="credits">Credits </label>
    <input
      id="credits"
      name="credits"
      class="p-inputtext-sm w-full"
      pInputText
      [formControl]="form.controls.credits"
      [readOnly]="true"
      placeholder="{calculated}"
    />
  </div>
</div>

<p-divider class="w-full" align="left" *ngIf="isTrainingNotSelfPaced">
  <b>Date</b>
</p-divider>

<div class="flex flex-column gap-4 mt-2 w-full" *ngIf="isTrainingNotSelfPaced">
  <div class="flex flex-col w-full">
    <label for="startDate">Start</label>
    <p-calendar
      dateFormat="dd/mm/yy"
      class="w-full"
      inputId="startDate"
      name="startDate"
      [firstDayOfWeek]="1"
      [showIcon]="true"
      [formControl]="form.controls.date.controls.startDate"
      (onSelect)="startDateValueChange.emit($event)"
      (onInput)="startDateValueChange.emit($event)"
    />
  </div>
  <div class="flex flex-col w-full">
    <label for="endDate">End</label>
    <p-calendar
      dateFormat="dd/mm/yy"
      inputId="endDate"
      name="endDate"
      [firstDayOfWeek]="1"
      [showIcon]="true"
      [formControl]="form.controls.date.controls.endDate"
    />
  </div>
  <div class="flex flex-col w-full">
    <label for="deadline">Deadline</label>
    <p-calendar
      dateFormat="dd/mm/yy"
      inputId="deadline"
      name="deadline"
      [firstDayOfWeek]="1"
      [showIcon]="true"
      [formControl]="form.controls.date.controls.deadline"
    />
  </div>
</div>
<p *ngIf="form.controls.date.controls.endDate.value && form.controls.date.hasError('falseDate')" class="text-error">
  End date should be after start date
</p>
<p *ngIf="form.controls.date.hasError('startAndEndNotInSeason')" class="text-error">
  Start and end date should be in the selected season
</p>
<p *ngIf="form.controls.date.hasError('falseDeadline')" class="text-error">Deadline should be before start date</p>
<!-- <p
  *ngIf="
    form.controls.name.dirty && (form.controls.name.hasError('required') || form.controls.name.hasError('whitespace'))
  "
  class="text-error"
>
  Name is required.
</p> -->
