<tc-loader *ngIf="bookings.isLoading() || seasons.isLoading() || pms.isLoading()" />
<div
  class="flex flex-col lg:flex-row gap-4 p-4 min-h-full w-full"
  *ngIf="!bookings.isLoading() && !seasons.isLoading() && !pms.isLoading()"
>
  <div class="flex flex-col md:flex-row lg:min-w-1/5 lg:flex-col gap-4">
    <tc-filter-facet
      type="single"
      [selected]="seasonFilters()"
      (selectedChange)="onSetSeasons($event)"
      [options]="seasonOptions()"
      [default]="['0']"
      header="Season"
      class="w-full hidden md:block"
      *ngIf="seasonOptions().length > 0"
    />
    <tc-filter-facet
      type="multiple"
      [selected]="statusFilters()"
      (selectedChange)="statusFilters.set($event)"
      [options]="statusOptions"
      header="Status"
      class="w-full hidden md:block"
      *ngIf="statusOptions.length > 0 && bookings.data() && bookings.data()!.length > 0"
    />
    <tc-filter-facet
      type="multiple"
      [selected]="pmFilters()"
      (selectedChange)="pmFilters.set($event)"
      [options]="pms.data() ?? []"
      storageId="booking-hr-pms"
      header="Practice Manager"
      class="w-full"
      *ngIf="pms.data() && pms.data()!.length > 0 && bookings.data()!.length > 0"
    />
    <div class="flex flex-col gap-4">
      <tc-dropdown-facet
        [selected]="selectedRequester()"
        (dropdownChange)="requesterFilters.set($event)"
        [options]="requesters()"
        header="Requester"
        class="w-full hidden md:block"
        *ngIf="requesters().length > 0 && bookings.data() && bookings.data()!.length > 0"
      />
      <tc-dropdown-facet
        [selected]="selectedGroupBy()"
        (dropdownChange)="groupByFilters.set($event)"
        [options]="groupByPossibilities"
        header="Group by"
        class="w-full hidden md:block"
        *ngIf="bookings.data() && bookings.data()!.length > 0"
      />
    </div>
  </div>

  <p-card class="w-full overflow-hidden">
    <tc-booking-filter-header
      [isExporting]="exportBookingsMutation.isLoading()"
      [isSelectedSeasonActiveSeason]="isSelectedSeasonActiveSeason()"
      (searchValueChange)="searchValue.set($event)"
      [searchValue]="searchValue()"
      [bookingsSelected]="selectedBookings().length"
      (bookBooking)="onBook()"
      (cancelBooking)="onCancel()"
      (verifyBooking)="onVerify()"
      (exportBookings)="onExportBookings()"
      (deleteAddedBookings)="onDeleteAddedBookings()"
    />
    <p-divider />

    <tc-booking-hr-table-grouped
      *ngIf="selectedGroupBy() && selectedGroupBy()?.key !== 'none'"
      class="hidden md:block h-full"
      [seasons]="selectedSeason()"
      [groupedBy]="selectedGroupBy()?.key"
      [allBookings]="bookings.data() ?? []"
      [bookings]="filteredBookings()"
      [selectedBookings]="selectedBookings()"
      (selectBookings)="onSelectBooking($event)"
    />

    <tc-booking-hr-table
      *ngIf="!selectedGroupBy() || selectedGroupBy()?.key === 'none'"
      class="hidden md:block"
      [bookings]="filteredBookings()"
      [tableSort]="sort()"
      [selectedBookings]="selectedBookings()"
      (selectBookings)="onSelectBooking($event)"
      (sortChanges)="onSortChanges($event.sort, $event.order)"
    />
    <tc-booking-hr-table class="md:hidden" [bookings]="bookingsForMobile()" />
  </p-card>
</div>

<p-confirmDialog
  #cd
  key="dialogWithInputForApprove"
  rejectButtonStyleClass="p-button-outlined p-button-sm"
  acceptButtonStyleClass="p-button-sm"
>
  <ng-template pTemplate="message">
    <div>
      <div class="flex content-center">
        <i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem"></i>
        <p class="mb-4">Are you sure you want to cancel {{ selectedBookings().length }} booking(s)?</p>
      </div>
      <form [formGroup]="form">
        <label for="motivation">Comment <span class="text-error">*</span></label>
        <textarea
          rows="3"
          id="motivation"
          name="motivation"
          pInputTextarea
          class="w-full"
          [formControl]="form.controls.comment"
        ></textarea>
        <div class="text-error">
          <p
            *ngIf="
              form.controls.comment.dirty &&
              (form.controls.comment.hasError('required') || form.controls.comment.hasError('whitespace'))
            "
          >
            Comment is required.
          </p>
          <p *ngIf="form.controls.comment.dirty && form.controls.comment.hasError('maxlength')" class="text-error">
            Comment is too long.
          </p>
        </div>
        <div class="flex align-baseline gap-2">
          <p-checkbox
            [value]="form.controls.refund"
            [binary]="true"
            inputId="refund"
            name="refund"
            [formControl]="form.controls.refund"
          />
          <label for="refund">Refund credits</label>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="onAccept()"></button>
  </ng-template>
</p-confirmDialog>
