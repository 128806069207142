/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { TrainingCatalogApiConfiguration } from '../training-catalog-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BulkMailInfoDto } from '../models/bulk-mail-info-dto';
import { HandlerResult } from '../models/handler-result';

@Injectable({ providedIn: 'root' })
export class EmailService extends BaseService {
  constructor(config: TrainingCatalogApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendToApprove()` */
  static readonly SendToApprovePath = '/api/Email/ToApprove';

  /**
   * Sends an email to the specified email address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendToApprove()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendToApprove$Response(
    params?: {
  
    /**
     * The email address to send the email to.
     */
    body?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.SendToApprovePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sends an email to the specified email address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendToApprove$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendToApprove(
    params?: {
  
    /**
     * The email address to send the email to.
     */
    body?: string
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sendToApprove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendRejected()` */
  static readonly SendRejectedPath = '/api/Email/Rejected';

  /**
   * Sends an email to the specified email address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendRejected()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendRejected$Response(
    params?: {
  
    /**
     * The email address to send the email to.
     */
    body?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.SendRejectedPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sends an email to the specified email address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendRejected$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendRejected(
    params?: {
  
    /**
     * The email address to send the email to.
     */
    body?: string
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sendRejected$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendApproved()` */
  static readonly SendApprovedPath = '/api/Email/Approved';

  /**
   * Sends an email to the specified email address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendApproved()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendApproved$Response(
    params?: {
  
    /**
     * The email address to send the email to.
     */
    body?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.SendApprovedPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sends an email to the specified email address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendApproved$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendApproved(
    params?: {
  
    /**
     * The email address to send the email to.
     */
    body?: string
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sendApproved$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendBooked()` */
  static readonly SendBookedPath = '/api/Email/Booked';

  /**
   * Sends an email to the specified email address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendBooked()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendBooked$Response(
    params?: {
  
    /**
     * The email address to send the email to.
     */
    body?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.SendBookedPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sends an email to the specified email address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendBooked$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendBooked(
    params?: {
  
    /**
     * The email address to send the email to.
     */
    body?: string
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sendBooked$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendCancelled()` */
  static readonly SendCancelledPath = '/api/Email/Cancelled';

  /**
   * Sends an email to the specified email address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCancelled()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendCancelled$Response(
    params?: {
  
    /**
     * The email address to send the email to.
     */
    body?: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.SendCancelledPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sends an email to the specified email address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCancelled$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendCancelled(
    params?: {
  
    /**
     * The email address to send the email to.
     */
    body?: string
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sendCancelled$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `remindConsultantsAboutAddedBookings()` */
  static readonly RemindConsultantsAboutAddedBookingsPath = '/api/Email/Reminders/Consultants';

  /**
   * Sends reminder emails to consultants with bookings in "Added" status for a specific season (used for reminder before season gets cleaned up).
   * The email prompts consultants to review and confirm the relevance of their booking in status "Added".
   * Only consultants with bookings in "Added" status during the specified season will receive this reminder.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `remindConsultantsAboutAddedBookings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  remindConsultantsAboutAddedBookings$Response(
    params?: {
  
    /**
     * A BulkMailInfoDto object containing the season year.
     */
    body?: BulkMailInfoDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HandlerResult>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.RemindConsultantsAboutAddedBookingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HandlerResult>;
      })
    );
  }

  /**
   * Sends reminder emails to consultants with bookings in "Added" status for a specific season (used for reminder before season gets cleaned up).
   * The email prompts consultants to review and confirm the relevance of their booking in status "Added".
   * Only consultants with bookings in "Added" status during the specified season will receive this reminder.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `remindConsultantsAboutAddedBookings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  remindConsultantsAboutAddedBookings(
    params?: {
  
    /**
     * A BulkMailInfoDto object containing the season year.
     */
    body?: BulkMailInfoDto
    },
    context?: HttpContext
  ): Observable<HandlerResult> {
    return this.remindConsultantsAboutAddedBookings$Response(params, context).pipe(
      map((r: StrictHttpResponse<HandlerResult>): HandlerResult => r.body)
    );
  }

  /** Path part for operation `remindHrAboutApprovedOrPendingBookings()` */
  static readonly RemindHrAboutApprovedOrPendingBookingsPath = '/api/Email/Reminders/Hr';

  /**
   * Sends reminder emails to HR for bookings with "Approved" or "Pending" status during a specific season.
   * The email prompts HR to review and address these bookings as needed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `remindHrAboutApprovedOrPendingBookings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  remindHrAboutApprovedOrPendingBookings$Response(
    params?: {
  
    /**
     * A BulkMailInfoDto object containing the season year.
     */
    body?: BulkMailInfoDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HandlerResult>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.RemindHrAboutApprovedOrPendingBookingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HandlerResult>;
      })
    );
  }

  /**
   * Sends reminder emails to HR for bookings with "Approved" or "Pending" status during a specific season.
   * The email prompts HR to review and address these bookings as needed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `remindHrAboutApprovedOrPendingBookings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  remindHrAboutApprovedOrPendingBookings(
    params?: {
  
    /**
     * A BulkMailInfoDto object containing the season year.
     */
    body?: BulkMailInfoDto
    },
    context?: HttpContext
  ): Observable<HandlerResult> {
    return this.remindHrAboutApprovedOrPendingBookings$Response(params, context).pipe(
      map((r: StrictHttpResponse<HandlerResult>): HandlerResult => r.body)
    );
  }

  /** Path part for operation `remindPracticeManagersAboutRequestedBookings()` */
  static readonly RemindPracticeManagersAboutRequestedBookingsPath = '/api/Email/Reminders/PracticeManagers';

  /**
   * Sends reminder emails to training Pms with trainings in "Requested" status for a specific season.
   * The email prompts them to review and address the requested trainings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `remindPracticeManagersAboutRequestedBookings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  remindPracticeManagersAboutRequestedBookings$Response(
    params?: {
  
    /**
     * A BulkMailInfoDto object containing the season year.
     */
    body?: BulkMailInfoDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HandlerResult>> {
    const rb = new RequestBuilder(this.rootUrl, EmailService.RemindPracticeManagersAboutRequestedBookingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HandlerResult>;
      })
    );
  }

  /**
   * Sends reminder emails to training Pms with trainings in "Requested" status for a specific season.
   * The email prompts them to review and address the requested trainings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `remindPracticeManagersAboutRequestedBookings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  remindPracticeManagersAboutRequestedBookings(
    params?: {
  
    /**
     * A BulkMailInfoDto object containing the season year.
     */
    body?: BulkMailInfoDto
    },
    context?: HttpContext
  ): Observable<HandlerResult> {
    return this.remindPracticeManagersAboutRequestedBookings$Response(params, context).pipe(
      map((r: StrictHttpResponse<HandlerResult>): HandlerResult => r.body)
    );
  }

}
