<p-card id="cardId">
  <form [formGroup]="form">
    <div>
      <label for="name">Name <span class="text-error">*</span></label>
      <input
        id="name"
        name="name"
        class="p-inputtext-sm w-full"
        pInputText
        [formControl]="form.controls.name"
        [autocomplete]="true"
      />
      <p
        *ngIf="
          form.controls.name.dirty &&
          (form.controls.name.hasError('required') || form.controls.name.hasError('whitespace'))
        "
        class="text-error"
      >
        Name is required
      </p>
      <p *ngIf="form.controls.name.dirty && form.controls.name.hasError('maxlength')" class="text-error">
        Name is too long
      </p>
    </div>

    <div class="flex flex-col 2xl:flex-row mt-2 w-full 2xl:gap-2">
      <div class="2xl:w-2/3">
        <div class="flex flex-row gap-2">
          <div class="flex flex-col w-full">
            <label for="startDate">Start</label>
            <p-calendar
              dateFormat="dd/mm/yy"
              class="w-full"
              inputId="startDate"
              name="startDate"
              [firstDayOfWeek]="1"
              [showIcon]="true"
              [formControl]="form.controls.date.controls.startDate"
              (onSelect)="startDateValueChange.emit($event)"
              (onInput)="startDateValueChange.emit($event)"
            />
          </div>
          <div class="flex flex-col w-full">
            <label for="endDate">End</label>
            <p-calendar
              dateFormat="dd/mm/yy"
              inputId="endDate"
              name="endDate"
              [firstDayOfWeek]="1"
              [showIcon]="true"
              [formControl]="form.controls.date.controls.endDate"
            />
          </div>
        </div>
        <p
          *ngIf="form.controls.date.controls.endDate.value && form.controls.date.hasError('falseDate')"
          class="text-error"
        >
          End date should be after start date
        </p>
        <p *ngIf="form.controls.date.hasError('startAndEndNotInSeason')" class="text-error">
          Start and end date should be in the selected season
        </p>
        <p-button *ngIf="season" (click)="onSetSeasonDates()" [link]="true" label="Take season dates"></p-button>
      </div>

      <div class="flex flex-col w-full 2xl:w-1/3">
        <label for="deadline">Deadline</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          inputId="deadline"
          name="deadline"
          [firstDayOfWeek]="1"
          [showIcon]="true"
          [formControl]="form.controls.date.controls.deadline"
        />
      </div>
    </div>
    <p *ngIf="form.controls.date.hasError('falseDeadline')" class="text-error">Deadline should be before start date</p>

    <div class="mt-2">
      <label for="description">Description</label>
      <textarea
        id="description"
        name="description"
        rows="2"
        class="w-full"
        pInputTextarea
        [formControl]="form.controls.description"
      ></textarea>
    </div>

    <div>
      <label for="comment">Comment</label>
      <input
        id="comment"
        name="comment"
        class="p-inputtext-sm w-full"
        pInputText
        [formControl]="form.controls.comment"
      />
    </div>

    <div class="flex flex-column gap-2 mt-2 w-full">
      <div class="flex flex-col w-full">
        <label for="cost">Cost <span class="text-error">*</span></label>
        <input
          id="cost"
          name="cost"
          type="number"
          class="p-inputtext-sm w-full"
          min="0"
          pInputText
          [formControl]="form.controls.cost"
          (input)="onCalculateCredits()"
        />
        <p *ngIf="form.controls.cost.dirty && form.controls.cost.hasError('required')" class="text-error">
          Cost is required
        </p>

        <p *ngIf="form.controls.cost.dirty && form.controls.cost.hasError('min')" class="text-error">
          Cost should be positive
        </p>
        <p *ngIf="form.controls.cost.dirty && form.controls.cost.hasError('pattern')" class="text-error">
          Cost doesn't allow commas
        </p>
      </div>
      <div class="flex flex-col w-full">
        <label for="halfDays">Half Days</label>
        <input
          id="halfDays"
          name="halfDays"
          type="number"
          min="0"
          class="p-inputtext-sm w-full"
          pInputText
          [formControl]="form.controls.halfDays"
          (input)="onCalculateCredits()"
        />
        <p *ngIf="form.controls.halfDays.dirty && form.controls.halfDays.hasError('min')" class="text-error">
          Half days should be positive
        </p>
        <p *ngIf="form.controls.halfDays.dirty && form.controls.halfDays.hasError('pattern')" class="text-error">
          Half days doesn't allow commas
        </p>
      </div>

      <div class="flex flex-col w-full text-secondary">
        <label for="credits">Credits </label>
        <input
          id="credits"
          name="credits"
          class="p-inputtext-sm w-full"
          pInputText
          [formControl]="form.controls.credits"
          [readOnly]="true"
          placeholder="{calculated}"
        />
      </div>
    </div>

    <div class="mt-2">
      <p-checkbox [formControl]="form.controls.default" [binary]="true" [inputId]="index.toString()" />
      <label [for]="index.toString()" class="ml-2 my-auto">Default</label>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <div class="flex gap-4">
      <p-button
        size="small"
        [outlined]="true"
        label="Copy option"
        icon="pi pi-copy"
        [disabled]="copyOptionIsDisabled()"
        (onClick)="onCopyOption()"
      />
      <p-button
        size="small"
        [outlined]="true"
        severity="danger"
        label="Delete option"
        icon="pi pi-times"
        [disabled]="deleteOptionIsDisabled"
        (onClick)="onDeleteOption()"
      />
    </div>
  </ng-template>
</p-card>
