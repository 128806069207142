import { BookingStatus } from '@/shared/models/BookingStatus';
import { BookingDetail } from '@/shared/models/bookingDetail';
import { TrainingDetail } from '@/shared/models/training-detail';
import { FormatDatePipe } from '@/shared/pipes/format-date-pipe';
import { StatusDisplayPipe } from '@/shared/pipes/status-display-pipe';
import { UserProfileDetail, UsersService } from '@/shared/services/user.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { AbstractControl, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { useAuth } from '@euricom/angular-shared';
import { ConfirmationService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { CancelResult } from '../booking-cancel-panel/booking-cancel-panel.component';

@Component({
  selector: 'tc-booking-info',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DividerModule,
    AvatarModule,
    TableModule,
    FormatDatePipe,
    StatusDisplayPipe,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    ConfirmDialogModule,
  ],
  providers: [UsersService],
  templateUrl: './booking-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingInfoComponent {
  private _booking = signal<BookingDetail | undefined>(undefined);
  private _training = signal<TrainingDetail | undefined>(undefined);
  readonly user = useAuth().user;
  readonly userImageError = signal<boolean>(false);

  @Output() cancel = new EventEmitter<CancelResult>();
  @Output() delete = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() changeStatus = new EventEmitter<{
    status: BookingStatus;
    comment?: string;
  }>();
  @Input() ownBooking?: boolean;
  @Input() isUpdated = false;

  readonly statusOptions = [
    { label: 'Added', value: 'Added' },
    { label: 'Requested', value: 'Requested' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Pending Booking', value: 'PendingBooking' },
    { label: 'Booked', value: 'Booked' },
    { label: 'Verified', value: 'Verified' },
    { label: 'Rejected', value: 'Rejected' },
  ];

  // Modify the form to use BookingStatus type
  // readonly changeStatusForm = this._formBuilder.group({
  //   status: [null as BookingStatus | null, Validators.required],
  //   comment: ['', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]],
  // });

  private _userProfile = signal<UserProfileDetail | undefined>(undefined);

  @Input()
  set userProfile(value: UserProfileDetail | undefined) {
    this._userProfile.set(value);
  }
  get userProfile() {
    return this._userProfile();
  }

  @Input()
  set booking(value: BookingDetail | undefined) {
    this._booking.set(value);
  }
  get booking() {
    return this._booking();
  }

  @Input()
  set training(value: TrainingDetail | undefined) {
    this._training.set(value);
  }
  get training() {
    return this._training();
  }

  constructor(private _confirmationService: ConfirmationService, private _formBuilder: FormBuilder) {}

  // Helper method to validate no whitespace
  noWhitespaceValidator(control: AbstractControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  onCancel() {
    this._confirmationService.confirm({
      message: 'Are you sure?',
      header: 'Confirmation cancel',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.cancel.emit(),
    });
  }

  onDelete() {
    this._confirmationService.confirm({
      message: 'Are you sure?',
      header: 'Confirmation delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.delete.emit(),
    });
  }

  onDeleteAdmin() {
    this._confirmationService.confirm({
      message: 'Are you sure?',
      header: 'Confirmation delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.delete.emit(),
    });
  }

  onImageError() {
    this.userImageError.set(true);
  }

  // onChangeStatus() {
  //   this._confirmationService.confirm({
  //     header: 'Change Booking Status',
  //     key: 'dialogWithStatusChangeOptions',
  //     message: 'Are you sure you want to change the booking status?',
  //   });
  // }

  // onAccept() {
  //   if (this.changeStatusForm.invalid) {
  //     this.changeStatusForm.markAllAsTouched();
  //     return;
  //   }

  //   const status = this.changeStatusForm.get('status')?.value as BookingStatus;
  //   const comment = this.changeStatusForm.get('comment')?.value?.trim();

  //   // Emit status with optional comment
  //   this.changeStatus.emit({
  //     status,
  //     comment: comment || undefined, // Only include comment if it's not empty
  //   });

  //   this.changeStatusForm.reset({
  //     status: null,
  //     comment: '',
  //   });
  // }
}
