import { getKnowledgeDomainForm } from '@/bookings/pages/bookings-hr/helpers';
import { KnowledgeDomain } from '@/shared/models/knowledge-domain';
import { KnowledgeDomainsService } from '@/shared/services/knowledge-domains.service';
import { ToastService } from '@/shared/services/toast.service';
import { useMutation } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'tc-knowledge-domains-add',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputTextModule, ButtonModule, ConfirmDialogModule],
  templateUrl: './knowledge-domains-add.component.html',
})
export class KnowledgeDomainsUpsertComponent {
  @Input({ required: true }) existingDomains: KnowledgeDomain[] = [];
  @Output() domainCreated = new EventEmitter<KnowledgeDomain>();

  readonly knowledgeDomainForm = getKnowledgeDomainForm();

  readonly createKnowledgeDomain = useMutation(this._knowledgeDomainService.addKnowledgeDomainAsync, {
    onSuccess: (createdDomain) => {
      this.domainCreated.emit(createdDomain);

      this._confirmationService.close();

      this.knowledgeDomainForm.reset();
    },
    onError: (error) => {
      this._toaster.error('Failed to create knowledge domain');
      console.error('Knowledge domain creation error:', error);
    },
  });
  constructor(
    private _toaster: ToastService,
    private _confirmationService: ConfirmationService,
    private _knowledgeDomainService: KnowledgeDomainsService,
  ) {}

  onCancel() {
    this._confirmationService.close();
  }

  onAcceptCreateNewDomain() {
    this.knowledgeDomainForm.controls.knowledgeDomain.markAsDirty();

    if (this.knowledgeDomainForm.invalid) return;

    const newDomainName = this.knowledgeDomainForm.controls.knowledgeDomain.value?.trim().toLowerCase();

    // Check if the domain already exists (case-insensitive)
    const domainExists = this.existingDomains?.some((domain) => domain.name.toLowerCase() === newDomainName);

    if (domainExists) {
      this._toaster.warning('Knowledge domain already exists');
      return;
    }

    this.createKnowledgeDomain.mutate({ name: newDomainName.charAt(0).toUpperCase() + newDomainName.slice(1) });
  }
}
