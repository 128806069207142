<p-confirmDialog #cd key="dialogWithInputForCreateKnowledgeDomain">
  <ng-template pTemplate="message">
    <div>
      <div class="flex content-center">
        <p class="mb-4">How would you like to call this domain?</p>
      </div>

      <form [formGroup]="knowledgeDomainForm">
        <input
          id="knowledgdeDomain"
          name="knowledgdeDomain"
          pInputText
          class="w-full"
          [formControl]="knowledgeDomainForm.controls.knowledgeDomain"
        />
        <div class="text-error">
          <p
            *ngIf="
              knowledgeDomainForm.controls.knowledgeDomain.dirty &&
              (knowledgeDomainForm.controls.knowledgeDomain.hasError('required') ||
                knowledgeDomainForm.controls.knowledgeDomain.hasError('whitespace'))
            "
          >
            Input is required.
          </p>
          <p
            *ngIf="
              knowledgeDomainForm.controls.knowledgeDomain.dirty &&
              knowledgeDomainForm.controls.knowledgeDomain.hasError('maxlength')
            "
            class="text-error"
          >
            Input is too long. (max. 30 char.)
          </p>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button type="button" [outlined]="true" icon="pi pi-times" label="Cancel" (click)="onCancel()"></p-button>
    <p-button type="button" icon="pi pi-check" label="Add" (click)="onAcceptCreateNewDomain()"></p-button>
  </ng-template>
</p-confirmDialog>
