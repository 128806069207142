<header class="flex justify-end">
  <article *ngIf="admin" class="flex gap-2">
    <p-button
      icon="pi pi-envelope"
      pTooltip="Send a reminder to all users with trainings that have the status 'Added', asking if they are still relevant for the selected season."
      tooltipPosition="left"
      size="small"
      [loading]="isLoading.toConsultants"
      [disabled]="isLoading.toConsultants"
      (onClick)="bulkMailConsultants.emit()"
      label="Bulk mail Consultants"
    />
    <p-button
      icon="pi pi-envelope"
      pTooltip="Send reminder to HR about all bookings with status 'Approved' and 'Pending Booking' for the selected season."
      tooltipPosition="left"
      size="small"
      label="Bulk mail HR"
      (onClick)="bulkMailHr.emit()"
      [disabled]="isLoading.toHr"
      [loading]="isLoading.toHr"
    />
    <p-button
      icon="pi pi-envelope"
      pTooltip="Send a reminder to Practice Managers about 'Requested' trainings for the selected season."
      tooltipPosition="left"
      size="small"
      label="Bulk mail PMs"
      [disabled]="isLoading.toPms"
      [loading]="isLoading.toPms"
      (onClick)="bulkMailPracticeManagers.emit()"
    />
  </article>
</header>
