import { FilterFacetComponent } from '@/shared/components/filter-facet/filter-facet.component';
import { LoaderComponent } from '@/shared/components/loader/loader.component';
import { SortComponent, SortOption, SortOptionKeys } from '@/shared/components/sort/sort.component';
import { PageTitleService } from '@/shared/services/page-title.service';
import { SearchParamsService } from '@/shared/services/search-param.service';
import { TrainingsService, getDomains, getFormats, trainingKeys } from '@/shared/services/training.service';
import { useQuery } from '@/shared/utils/query';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed, effect, signal } from '@angular/core';
import { startOfToday } from 'date-fns';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { TagModule } from 'primeng/tag';
import { TrainingFilterHeaderComponent } from '../../components/training-filter-header/training-filter-header.component';
import { TrainingsDataViewComponent } from '../../components/trainings-data-view/trainings-data-view.component';
import { filterAndSortTrainings } from './helpers';

type SearchParams = {
  search: string;
  sort: SortOptionKeys | null;
  domain: string[];
  format: string[];
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    // primeNG
    DataViewModule,
    TagModule,
    CardModule,
    DividerModule,
    ButtonModule,
    // custom
    TrainingsDataViewComponent,
    TrainingFilterHeaderComponent,
    SortComponent,
    FilterFacetComponent,
    LoaderComponent,
  ],
  providers: [TrainingsService, SearchParamsService],
  selector: 'tc-home',
  templateUrl: './trainings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingsComponent implements OnInit {
  readonly trainings = useQuery(trainingKeys.available, async () => {
    const today = startOfToday();

    const trainings = await this._trainingService.getTrainingsAsync({
      afterDate: today,
    });
    return trainings.filter((item) => item.isEnabled !== undefined);
  });

  readonly filteredTrainings = computed(() => {
    return filterAndSortTrainings(
      this.trainings.data,
      this.searchExpression,
      this.domainFilters,
      this.formatFilters,
      this.sort,
    );
  });

  readonly domains = computed(() => {
    return getDomains(this.trainings.data);
  });

  readonly formats = computed(() => {
    return getFormats(this.trainings.data);
  });

  searchParams = this._searchParamService.getSearchParams<SearchParams>();
  readonly searchExpression = signal(this.searchParams.search);
  // sort default by date
  readonly sort = signal<SortOptionKeys | null>(this.searchParams.sort ?? 'Date');
  readonly domainFilters = signal<string[]>(this.searchParams.domain);
  readonly formatFilters = signal<string[]>(this.searchParams.format);

  readonly sortOptions: Array<SortOption>;

  constructor(
    private _pageTitleService: PageTitleService,
    private _trainingService: TrainingsService,
    private _searchParamService: SearchParamsService,
  ) {
    this.sortOptions = [
      { key: 'TitleAsc', value: 'Title (a-z)' },
      { key: 'TitleDesc', value: 'Title (z-a)' },
      { key: 'Date', value: 'Date' },
      { key: 'Deadline', value: 'Deadline' },
    ];

    // update the query params when the search params change
    effect(() => {
      const queryParams = {
        search: this.searchExpression() || undefined, // must be undefined to delete the search when not set
        sort: this.sort(),
        domain: this.domainFilters(),
        format: this.formatFilters(),
      };
      _searchParamService.setQueryParams(queryParams);
    });
  }

  ngOnInit() {
    this._pageTitleService.setPageTitle('Trainings');
  }

  onSort(sortOption: SortOption) {
    this.sort.set(sortOption.key);
  }

  onRequestNewTraining() {
    window.open('https://forms.office.com/r/ndWbqZCEdp', '_blank');
  }
}
