import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'tc-season-admin-header',
  standalone: true,
  imports: [CommonModule, ButtonModule, TooltipModule],
  templateUrl: './season-admin-header.component.html',
})
export class SeasonAdminHeaderComponent {
  @Input({ required: true }) admin = false;

  @Input({ required: true }) isLoading: {
    toConsultants: boolean;
    toHr: boolean;
    toPms: boolean;
  } = {
    toConsultants: false,
    toHr: false,
    toPms: false,
  };

  @Output() bulkMailConsultants = new EventEmitter();
  @Output() bulkMailHr = new EventEmitter();
  @Output() bulkMailPracticeManagers = new EventEmitter();
}
