import { CollectionsAdminComponent } from '@/collections/pages/collections-admin/collections-admin.component';
import { CollectionsUpsertComponent } from '@/collections/pages/collections-upsert/collections-upsert.component';
import { MyReviewsComponent } from '@/reviews/pages/my-reviews/my-reviews.component';
import { ReviewUpdateComponent } from '@/reviews/pages/review-update/review-update.component';
import { SeasonUpsertComponent } from '@/seasons/pages/season-upsert/season-upsert.component';
import { RoleGuard } from '@/shared/guards/role.guard';
import { NoInternetConnectionComponent } from '@/shared/layouts/no-internet-connection/no-internet-connection';
import { PageNotFoundComponent } from '@/shared/layouts/page-not-found/page-not-found.component';
import { AppRoles } from '@/shared/utils/roles';
import { StandInUpsertComponent } from '@/stand-ins/pages/stand-in-upsert/stand-in-upsert.component';
import { AdminStandInsComponent } from '@/stand-ins/pages/stand-ins-admin/stand-ins-admin.component';
import { TrainingUpsertComponent } from '@/trainings/pages/training-upsert/training-upsert.component';
import { TrainingsCalendarComponent } from '@/trainings/pages/trainings-calendar/trainings-calendar.component';
import { Route } from '@angular/router';
import { BookingDetailComponent } from './bookings/pages/booking-detail/booking-detail.component';
import { BookingsHrComponent } from './bookings/pages/bookings-hr/bookings-hr.component';
import { BookingsOverviewComponent } from './bookings/pages/bookings-overview/bookings-overview.component';
import { LoginComponent } from './login/pages/login.component';
import { ManageComponent } from './manage/pages/manage.component';
import { ReviewsFollowUpComponent } from './reviews/pages/all-reviews/all-reviews.component';
import { AdminSeasonsComponent } from './seasons/pages/seasons-admin/seasons-admin.component';
import { LoggedInGuard } from './shared/guards/logged-in.guard';
import { guardNotLoggedIn } from './shared/guards/not-logged-in.guard';
import { ScreenGuard } from './shared/guards/screen.guard';
import { MainLayoutComponent } from './shared/layouts/main-layout/main-layout.component';
import { TrainingDetailComponent } from './trainings/pages/training-detail/training-detail.component';
import { TrainingFollowUpDetailComponent } from './trainings/pages/training-follow-up-detail/training-follow-up-detail.component';
import { TrainingsFollowUpComponent } from './trainings/pages/training-follow-up/training-follow-up.component';
import { AdminTrainingsComponent } from './trainings/pages/trainings-admin/trainings-admin.component';
import { TrainingsComponent } from './trainings/pages/trainings/trainings.component';

export const appRoutes: Route[] = [
  { path: 'login', component: LoginComponent, canActivate: [guardNotLoggedIn] },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [LoggedInGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'trainings' },
      { path: 'trainings', component: TrainingsComponent },
      { path: 'trainings-calendar', component: TrainingsCalendarComponent },
      { path: 'bookings', component: BookingsOverviewComponent },
      { path: 'trainings/:id', component: TrainingDetailComponent },
      { path: 'trainings/:id/detail', component: TrainingDetailComponent },
      { path: 'my-reviews', component: MyReviewsComponent },
      { path: 'my-reviews/:id', component: ReviewUpdateComponent },
      {
        path: 'follow-up',
        canActivate: [RoleGuard],
        data: { roles: [AppRoles.Administrator, AppRoles.PracticeManager, AppRoles.CatalogResponsible] },
        children: [
          { path: 'bookings', component: BookingsHrComponent },
          { path: 'bookings/:id', component: BookingDetailComponent },
          { path: 'trainings', component: TrainingsFollowUpComponent },
          { path: 'trainings/:id', component: TrainingFollowUpDetailComponent },
          { path: 'reviews', component: ReviewsFollowUpComponent },
          { path: 'stand-ins', component: AdminStandInsComponent },
          { path: 'stand-ins/add', component: StandInUpsertComponent },
          { path: 'stand-ins/:id', component: StandInUpsertComponent },
        ],
      },
      { path: 'bookings/:id', component: BookingDetailComponent },
      { path: '404', component: PageNotFoundComponent },
      { path: '0', component: NoInternetConnectionComponent },
      {
        path: 'admin',
        canActivate: [RoleGuard, ScreenGuard],
        data: { roles: [AppRoles.Administrator, AppRoles.CatalogResponsible] },
        children: [
          { path: 'trainings', component: AdminTrainingsComponent },
          { path: 'trainings/add', component: TrainingUpsertComponent },
          { path: 'trainings/:id', component: TrainingUpsertComponent },
          { path: 'collections', component: CollectionsAdminComponent },
          { path: 'collections/add', component: CollectionsUpsertComponent },
          { path: 'collections/:id', component: CollectionsUpsertComponent },
          { path: 'seasons', component: AdminSeasonsComponent },
          { path: 'seasons/add', component: SeasonUpsertComponent },
          { path: 'seasons/:id', component: SeasonUpsertComponent },
          { path: 'manage', component: ManageComponent },
          { path: '', pathMatch: 'full', redirectTo: '/404' },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '404' },
];
