import { PageTitleService } from '@/shared/services/page-title.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

export enum ManageOptionKey {
  PMs = 'PMs',
  Responsibles = 'Responsibles',
  Admins = 'Admins',
  HRs = 'HRs',
}

export interface ManageItem {
  title: string;
  link: string;
  key: ManageOptionKey;
}

@Component({
  selector: 'tc-manage',
  standalone: true,
  imports: [CommonModule, CardModule, ButtonModule],
  templateUrl: './manage.component.html',
})
export class ManageComponent implements OnInit {
  readonly manageItems: ManageItem[] = [
    {
      title: 'Practice Managers',
      link: 'https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupMembersList.ReactView/groupId/981aa718-4451-48a6-90e4-44bd09e79b1b',
      key: ManageOptionKey.PMs,
    },
    {
      title: 'Responsibles',
      link: 'https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupMembersList.ReactView/groupId/b88b488d-acbb-451f-b80d-dc2ab3289b18',
      key: ManageOptionKey.Responsibles,
    },
    {
      title: 'Admins',
      link: 'https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupMembersList.ReactView/groupId/11f15db2-07dd-46fc-b5ae-6fb10df63b82',
      key: ManageOptionKey.Admins,
    },
    {
      title: 'HRs',
      link: 'https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupMembersList.ReactView/groupId/89f39985-91cc-4ded-a9f4-bb6848588915',
      key: ManageOptionKey.HRs,
    },
  ];

  readonly ManageOptionKey = ManageOptionKey;

  constructor(private _pageTitleService: PageTitleService, private domSanitizer: DomSanitizer) {
    // Use a more generic Azure AD URL that might be more stabl
  }

  sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('https://euricom.timesheetzone.com/login');

  ngOnInit() {
    this._pageTitleService.setPageTitle('Manage groups');
  }

  // Function to open a new tab with the provided link
  openNewTab(item: ManageItem) {
    console.log(`Opening Azure Portal for ${item.title}`);
    window.open(item.link, '_blank');
  }
}
